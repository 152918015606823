import { Injectable } from "@angular/core";
import * as base64 from "base-64";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private baseUrl = environment.apiURL;
  private accessTokenKey = "iOnePredict";
  private refreshTokenKey = "iOnePredictRefresh";

  constructor(private http: HttpClient) {}

  checkAuthentication(): any {
    let token = this.getAccessToken();
    return token ? true : false;
  }

  login() {
    //For IDMS
    return this.http
      .post(
        `${this.baseUrl}/accesscontrol/Token`,
        {},
        { headers: { skip: "true" } }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  fetchToken(token: string, type: string) {
    return this.http
      .post(
        `${this.baseUrl}/accesscontrol/Token`,
        { token, type },
        { headers: { skip: "true" } }
      )
      .pipe(
        map((token) => {
          this.saveToken(token);
          return token;
        })
      );
  }

  setTokenOnLocal(token: string){
    const encodedToken = this.encodingTwice(token);
    localStorage.setItem(this.accessTokenKey, encodedToken);
  }

  private saveToken(token: any): void {
    if (token) {
      const encodedToken = this.encodingTwice(token.accessToken);
      localStorage.setItem(this.accessTokenKey, encodedToken);
      if (token.refreshToken) {
        localStorage.setItem(this.refreshTokenKey, token.refreshToken);
      }
    }
  }

  private encodingTwice(token: string): string {
    if (token) {
      const encodeLevel1 = base64.encode(token);
      const encodeLevel2 = base64.encode(encodeLevel1);
      return encodeLevel2;
    }
    return "";
  }

  fetchRefreshToken(token: any, type: string) {
    return this.http
      .post(
        `${this.baseUrl}/accesscontrol/Token`,
        { token, type },
        { headers: { skip: "true" } }
      )
      .pipe(
        map((token) => {
          if (token === "token_expired") {
            localStorage.clear();
          } else {
            this.saveToken(token);
          }
        })
      );
  }

  private decodingTwice(token: string): string {
    if (token) {
      const decodeLevel2 = base64.decode(token);
      const decodeLevel1 = base64.decode(decodeLevel2);
      return decodeLevel1;
    }
    return "";
  }

  localLogin(u: string, p: string) {
    return this.http
      .post(
        `${this.baseUrl}/auth/token`,
        { username: u, password: p },
        { responseType: "text" }
      )
      .pipe(
        map((response) => {
          const encodedToken = this.encodingTwice(response.toString());
          localStorage.setItem(this.accessTokenKey, encodedToken);
        })
      );
  }

  getAccessToken() {
    const encodedToken = localStorage.getItem(this.accessTokenKey);
    return encodedToken ? this.decodingTwice(encodedToken) : null;
  }

  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  getAccessKey() {
    return this.accessTokenKey;
  }

  getAccessRefreshKey() {
    return this.refreshTokenKey;
  }
}
