import { Injectable } from "@angular/core";
import { SharedValueService } from "../_services/shared-value.service";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../_services/authentication.service";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private sharedValueService: SharedValueService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (
            event?.url?.indexOf("accesscontrol/Otp") !== -1 ||
            event?.url?.indexOf("accesscontrol/Account") !== -1
          ) {
            event = event;
          } else {
            event = event.clone({ body: event.body.response });
          }
          this.sharedValueService.message = "";
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          console.log(`error status : ${error.status} ${error.statusText}`);
          if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
          } else {
            switch (error.status) {
              case 401:
                if (
                  this.authenticationService.getAccessToken() &&
                  this.authenticationService.getRefreshToken()
                ) {
                  //call for REFRESHTOKEN
                  console.log(
                    "user already logged in but got 401 so trying to get new token"
                  );
                  // localStorage.removeItem('ACCESS_TOKEN');
                  this.authenticationService
                    .fetchRefreshToken(
                      this.authenticationService.getRefreshToken(),
                      "REFRESHTOKEN"
                    )
                    .subscribe(() => {
                      console.log("got new token");
                      window.location.reload();
                    });
                } else {
                  this.router.navigate(["/"]);
                }
                break;
              case 403:
                // to handle peek user exceeds error
                this.sharedValueService.message = error.error.ErrorMessage;
                break;
              case 504:
                // to retry the call for status 504
                return next.handle(request).pipe(retry(2));
                break;
              default:
              //handle other cases
            }
          }
        }
        return throwError(() => error);
      })
    );
  }
}
