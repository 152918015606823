import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../helpers/authentication.service";
import { catchError, map, throwError } from "rxjs";

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const authService = inject(AuthenticationService);
  return next(req).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (
          event?.url?.indexOf("accesscontrol/Otp") !== -1 ||
          event?.url?.indexOf("accesscontrol/Account") !== -1
        ) {
          event = event;
        } else {
          event = event.clone({ body: event.body.response });
        }
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      if (
        error.error instanceof ErrorEvent ||
        error.error instanceof ProgressEvent
      ) {
        return throwError(() => new Error("No data"));
      } else {
        switch (error.status) {
          case 401:
            if (
              authService.getStoredAccessToken() &&
              authService.getStoredRefreshToken()
            ) {
              //call for REFRESHTOKEN
              // localStorage.removeItem('ACCESS_TOKEN');
              authService.getToken(authService.getStoredRefreshToken(), true);
            } else {
              router.navigate(["/"]);
            }
            break;
          case 403:
            // to handle peek user exceeds error
            // console.log(error.error.ErrorMessage);
            break;
          case 504:
            // to retry the call for status 504
            // return next.handle(request).pipe(
            //     retry(2)
            // );
            break;
          default:
          //handle other cases
        }
      }
      return throwError(() => error);
    })
  );
};
