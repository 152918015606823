import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UtilsService } from '../../helpers/utils.service';
import { Customer } from '../models/customer.model';
import { AccessControl } from '../../constants/access-control';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private baseUrl = `${environment.apiURL}/customers`;
  private accessControl = new AccessControl();

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService) {}

  getCustomers(): Observable<Customer[]> {
    return this.utilsService
    .httpGet(this.accessControl.adminSettings())
    .pipe(map(response => response.customers || []));
  }

  createCustomer(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.baseUrl, customer);
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    return this.http.put<Customer>(`${this.baseUrl}/${customer.id}`, customer);
  } 

  deleteCustomer(customerId: number): Observable<Customer> {
    return this.http.delete<Customer>(`${this.baseUrl}/${customerId}`);
  }
}