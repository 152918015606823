import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPredictService } from '../_services/i-predict.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../_services/shared.service';
import { User } from '../_models/user';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-ionecloud',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit, OnDestroy {

    appList: any;
    termsandConditions: any;
    private user!: User;
    isActive!: boolean;
    showTermsAndConditions: boolean = false;
    private subscription: Subscription = new Subscription();
    private userSubscription!: Subscription;
    innerHeight!: number;
    showStatus = false;
    showError = false;
    showMsg: string;
    constructor(
        private iPredictService: IPredictService,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.innerHeight = window.innerHeight;
        this.userSubscription = this.sharedService.getUser().subscribe(userData => {
            if (!isEmpty(userData)) {
                this.user = userData;
                this.isActive = this.user?.active;
                if (!this.isActive) {
                    this.showError = true;
                    this.showUIMsg('Your active subscription has expired or you don\'t have a valid subsription to proceed. Please contact support@smartcommcloud.intebg.com');
                }
                this.showApps();
            }
        });
    }

    showApps() {
        if (this.user.hasAgreementSigned) {
            this.subscription.add(this.iPredictService.getApplications().subscribe(response => {
                this.appList = response.applist;
            }));
        } else {
            this.subscription.add(this.iPredictService.getTermsAndConditions().subscribe(response => {
                this.showTermsAndConditions = true;
                this.termsandConditions = response['termsAndConditions'];
                this.getTermsAndConditions();
            }));
        }
        this.userSubscription?.unsubscribe();
    }

    getTermsAndConditions() {
        let el = document.getElementById('terms') as HTMLElement;
        el.click();
    }

    updateUserAccount(agreeTerms: boolean) {
        if (agreeTerms) {
            this.subscription.add(
                this.iPredictService.updateAgreement(agreeTerms).subscribe(data => {
                    this.appList = data.applist;
                })
            );
        }
    }

    showUIMsg(msg: string) {
        this.showStatus = true;
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.showMsg = msg;
        // setTimeout(() => { this.showStatus = false; }, 6000);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
