import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../_models/user';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private leftNav = new BehaviorSubject<boolean>(true);

    toggleSubMenu: boolean = true;
    // toggleSubMenuUser: boolean = false;
    private subMenuDMM = new BehaviorSubject<boolean>(this.toggleSubMenu);
    // private subMenuUser = new BehaviorSubject<boolean>(this.toggleSubMenuUser)
    private readonly _user = new BehaviorSubject<any>({} as User);

    adminPanel: boolean = true;
    public subMenuAdminPanel = new BehaviorSubject<boolean>(this.adminPanel);

    sites: boolean = true;
    private subMenuSites = new BehaviorSubject<boolean>(this.sites);

    digitalLogBook: boolean = true;
    private subMenuDLB = new BehaviorSubject<boolean>(this.digitalLogBook);

    sitePanel: boolean = false;
    private subSitePanel = new BehaviorSubject<boolean>(this.sitePanel);

    settingsMenu: boolean = true;
    private subMenuSettings = new BehaviorSubject<boolean>(this.settingsMenu);

    private readonly _adminSettings = new BehaviorSubject<any>(null);

    private _modules = new BehaviorSubject<any>({} as any);

    constructor(public snackBar: MatSnackBar) { }

    // Observable for left nav open and close

    notifyAction(action: boolean) {
        this.leftNav.next(action);
    }

    getAction(): Observable<boolean> {
        return this.leftNav.asObservable();
    }

    get user() {
        return this._user.getValue();
    }

    set user(value: User) {
        this._user.next(value)
    }

    addUser(data: User) {
        this.user = data;
    }

    getUser() {
        return this._user.asObservable();
    }

    get isOandM() {
        return this.user && this.user.role.name === 'EA.OandM';
    }

    toggleDMMSubMenu() {
        this.toggleSubMenu = !this.toggleSubMenu;
        this.subMenuDMM.next(this.toggleSubMenu);
    }

    getToggleSubMenu() {
        return this.subMenuDMM.asObservable();
    }

    toggleAdminPanelSubMenu() {
        this.adminPanel = !this.adminPanel
        this.subMenuAdminPanel.next(this.adminPanel);
    }

    toggleSitesSubMenu(tab?: string) {
        if (tab == 'assets') {
            this.sites = false;
        } else {
            this.sites = !this.sites;
        }
        this.subMenuSites.next(this.sites);
    }

    getToggleAdminPanelSubMenu() {
        return this.subMenuAdminPanel.asObservable();
    }

    getToggleSitesSubMenu() {
        return this.subMenuSites.asObservable();
    }

    toggleDLBSubMenu() {
        this.digitalLogBook = !this.digitalLogBook;
        this.subMenuDLB.next(this.digitalLogBook);
    }

    getToggleSubMenuDLB() {
        return this.subMenuDLB.asObservable();
    }

    toggleSettingsSubMenu() {
        this.settingsMenu = !this.settingsMenu;
        this.subMenuSettings.next(this.settingsMenu);
    }

    getToggleSubMenuSettings() {
        return this.subMenuSettings.asObservable();
    }

    toggleSitePanel() {
        this.sitePanel = !this.sitePanel;
        this.subSitePanel.next(this.sitePanel);
    }

    getToggleSitePanel() {
        return this.subSitePanel.asObservable();
    }

    get adminSettings() {
        return this._adminSettings.getValue()
    }

    set adminSettings(value: any) {
        this._adminSettings.next(value);
    }

    addAdminSettings(value: any) {
        this.adminSettings = value;
    }

    getAdminSettings() {
        return this._adminSettings.asObservable();
    }

    get modules() {
        return this._modules.getValue();
    }

    set modules(value: any) {
        this._modules.next(value)
    }

    addModule(data: any) {
        this.modules = data;
    }

    getModule() {
        return this._modules.asObservable();
    }

    showSnackBar(
        message: string,
        type?: string,
        action?: string,
        config: MatSnackBarConfig = {
            horizontalPosition: 'right',
            verticalPosition: 'top',
        }
    ): void {
        this.snackBar.open(message, action, {
            ...config,
            duration: action ? undefined : 3000,
            panelClass: ['alertMsg', type],
        });
    }

    convertLocalToUTC(localDate: string): string {
        // const localDateObj = new Date(localDate);
        // const utcDate = localDateObj.toISOString();
        // return utcDate;

        // Create a new Date object from the local date string
        const localDateObj = new Date(localDate);

        // Get the UTC date components
        const year = localDateObj.getUTCFullYear();
        const month = String(localDateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(localDateObj.getUTCDate()).padStart(2, '0');
        const hours = String(localDateObj.getUTCHours()).padStart(2, '0');
        const minutes = String(localDateObj.getUTCMinutes()).padStart(2, '0');
        const seconds = String(localDateObj.getUTCSeconds()).padStart(2, '0');

        // Format the UTC date string
        const utcDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        return utcDate;
    }

    convertUTCToLocal(utcDate: string): string {
        // const utcDateObj = new Date(utcDate);
        // const localDate = utcDateObj.toLocaleString();
        // return localDate;
        // Create a new Date object from the UTC date string
        const utcDateObj = new Date(utcDate + 'Z'); // Ensure the string is treated as UTC

        // Get the local date components
        const year = utcDateObj.getFullYear();
        const month = String(utcDateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(utcDateObj.getDate()).padStart(2, '0');
        const hours = String(utcDateObj.getHours()).padStart(2, '0');
        const minutes = String(utcDateObj.getMinutes()).padStart(2, '0');
        const seconds = String(utcDateObj.getSeconds()).padStart(2, '0');

        // Format the local date string
        const localDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        return localDate;
    }


}
