<div style="
width: 300px;
height: auto;
border: 1px solid gray;
background-color: black;
">
  <highcharts-chart
  [id]="target"
  [Highcharts]="Highcharts"
  [options]="chartOptions"
  style="width: 100%; height: 250px; display: block"
></highcharts-chart>
</div>

