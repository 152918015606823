import { APP_INITIALIZER, NgModule, importProvidersFrom, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { jwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { errorInterceptor } from "./core/interceptors/error.interceptor";
import { NgxPermissionsModule, NgxPermissionsService } from "ngx-permissions";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { OverlayContainer } from "@angular/cdk/overlay";
import { reducers } from "./core/state/reducers";
import { CustomersEffects } from "./core/state/effects/customers.effects";
import { SitesEffects } from "./core/state/effects/sites.effects";
import { MaterialModule } from "./shared/modules/material.module";
import { FormsModule } from "@angular/forms";
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { DemoComponent } from './demo.component';
import { HighchartsChartModule } from "highcharts-angular";

// export function PermissionsFactory(rbacService: RbacService, ngxpermissionsService: NgxPermissionsService) {
//     return () => {
//         return rbacService.loadPermissions().then((data) => { return ngxpermissionsService.loadPermissions(data) })
//     }
// }

@NgModule({
  declarations: [AppComponent, DemoComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    NgbModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([CustomersEffects, SitesEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    StoreRouterConnectingModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    HighchartsChartModule
  ],
  providers: [
    BrowserAnimationsModule,
    provideHttpClient(withInterceptors([jwtInterceptor, errorInterceptor])),
    // {
    //     provide: APP_INITIALIZER,
    //     useFactory: PermissionsFactory,
    //     deps: [RbacService, NgxPermissionsService],
    //     multi: true
    // }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add("light");
  }
}
