import { Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGuage from "highcharts/modules/solid-gauge";
HighchartsMore(Highcharts);
SolidGuage(Highcharts);
@Component({
  selector: "app-demo",
  templateUrl: "./demo.component.html",
  styleUrl: "./demo.component.scss",
})
export class DemoComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  target = "healthIndex";
  chartData = {
    indexRange: {
      HI1: 1,
      HI2: 2,
      HI3: 3,
      HI4: 4,
      HI5: 5,
    },
    indexValue: "HI2",
    indexStatus: "Good",
  };

  plotBands: any = [];
  tickPositions = [];
  selectedValue: any = 0;
  ranges: any;
  status: string;

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (this.chartData?.indexValue && this.chartData?.indexStatus) {
      this.selectedValue =
        this.chartData?.indexRange[this.chartData.indexValue];
      this.status =
        this.target === "contactWear" ? "No Data" : this.chartData?.indexStatus;
    } else {
      this.selectedValue = 0;
      this.status = "No Data";
    }

    this.formatPlotBands();
    this.createChart();
  }

  formatPlotBands() {
    const plotBandsMap = {
      maintenanceIndex: [
        { from: 0, to: 25, color: "#00943D", thickness: 7, text: "MI1" },
        { from: 25, to: 50, color: "#FFD000", thickness: 7, text: "MI2" },
        { from: 50, to: 75, color: "#EE8C19", thickness: 7, text: "MI3" },
        { from: 75, to: 100, color: "#DC272D", thickness: 7, text: "MI4" },
      ],
      crticalIndex: [
        { from: 0, to: 25, color: "#00943D", thickness: 7, text: "MI1" },
        { from: 25, to: 50, color: "#FFD000", thickness: 7, text: "MI2" },
        { from: 50, to: 75, color: "#EE8C19", thickness: 7, text: "MI3" },
        { from: 75, to: 100, color: "#DC272D", thickness: 7, text: "MI4" },
      ],
      healthIndex: [
        { from: 0, to: 20, color: "#00943D", thickness: 7, text: "HI1" },
        { from: 20, to: 40, color: "#FFE880", thickness: 7, text: "HI2" },
        { from: 40, to: 60, color: "#FFD000", thickness: 7, text: "HI3" },
        { from: 60, to: 80, color: "#EE8C19", thickness: 7, text: "HI4" },
        { from: 80, to: 100, color: "#DC272D", thickness: 7, text: "HI5" },
      ],
      riskIndex: [
        { from: 0, to: 25, color: "#00943D", thickness: 7, text: "HI1" },
        { from: 25, to: 50, color: "#FFD000", thickness: 7, text: "HI3" },
        { from: 50, to: 75, color: "#EE8C19", thickness: 7, text: "HI4" },
        { from: 75, to: 100, color: "#DC272D", thickness: 7, text: "HI5" },
      ],
      contactWear: [
        { from: 0, to: 25, color: "#00943D", thickness: 7, text: "HI1" },
        { from: 25, to: 50, color: "#FFD000", thickness: 7, text: "HI3" },
        { from: 50, to: 75, color: "#EE8C19", thickness: 7, text: "HI4" },
        { from: 75, to: 100, color: "#DC272D", thickness: 7, text: "HI5" },
      ],
    };

    const tickPositionsMap = {
      maintenanceIndex: [25, 50, 75, 100],
      crticalIndex: [25, 50, 75, 100],
      healthIndex: [20, 40, 60, 80, 100],
      riskIndex: [25, 50, 75, 100],
      contactWear: [25, 50, 75, 100],
    };

    this.plotBands = plotBandsMap[this.target] || [];
    this.tickPositions = tickPositionsMap[this.target] || [];
    this.selectedValue = this.tickPositions[this.selectedValue - 1] || 0;
  }

  getLabelText(index) {
    const labelMap = {
      maintenanceIndex: {
        0: "MI1",
        1: "MI2",
        2: "MI3",
        3: "MI4",
      },
      healthIndex: {
        0: "HI1",
        1: "HI2",
        2: "HI3",
        3: "HI4",
        4: "HI5",
      },
      crticalIndex: {
        12: "CI1",
        35: "CI2",
        65: "CI3",
        85: "CI4",
      },
      riskIndex: {
        12: "RI11",
        35: "RI22",
        65: "RI33",
        85: "RI44",
      },
      contactWear: {
        12: "HI1",
        35: "HI2",
        65: "HI3",
        85: "HI4",
      },
    };

    return labelMap[this.target]?.[index] || "";
  }

  plotBandsForRiskIndex() {
    const colors = ["#00943D", "#FFD000", "#EE8C19", "#DC272D"];
    const intVal = this.ranges[this.ranges.length - 1].value / 4;
    let prev;
    for (let index = 1; index <= 4; index++) {
      this.plotBands.push({
        from: index === 1 ? this.ranges[0].value : prev,
        to:
          index === 4
            ? this.ranges[this.ranges.length - 1].value
            : this.ranges[0].value + intVal * index,
        color: colors[index - 1],
        thickness: 7,
      });
      prev = this.ranges[0].value + intVal * index;
    }

    // console.log(this.plotBands);
  }

  setPieSeries() {
    const seriesMap = {
      maintenanceIndex: [
        { from: 0, to: 25, color: "#CCEAD8", name: "MI1", y: 25 },
        { from: 25, to: 50, color: "#fff6cc", name: "MI2", y: 25 },
        { from: 50, to: 75, color: "#fce8d1", name: "MI3", y: 25 },
        { from: 75, to: 100, color: "#f8d4d5", name: "MI4", y: 25 },
      ],
      crticalIndex: [
        { from: 0, to: 25, color: "#CCEAD8", name: "CI1", y: 25 },
        { from: 25, to: 50, color: "#fff6cc", name: "CI2", y: 25 },
        { from: 50, to: 75, color: "#fce8d1", name: "CI3", y: 25 },
        { from: 75, to: 100, color: "#f8d4d5", name: "CI4", y: 25 },
      ],
      healthIndex: [
        { from: 0, to: 20, color: "#00943D", name: "HI1", y: 20 },
        { from: 20, to: 40, color: "#FFE880", name: "HI2", y: 20 },
        { from: 40, to: 60, color: "#FFD000", name: "HI3", y: 20 },
        { from: 60, to: 80, color: "#EE8C19", name: "HI4", y: 20 },
        { from: 80, to: 100, color: "#DC272D", name: "HI5", y: 20 },
      ],
      riskIndex: [
        { from: 0, to: 25, color: "#CCEAD8", name: "RI1", y: 25 },
        { from: 25, to: 50, color: "#fff6cc", name: "RI3", y: 25 },
        { from: 50, to: 75, color: "#fce8d1", name: "RI4", y: 25 },
        { from: 75, to: 100, color: "#f8d4d5", name: "RI5", y: 25 },
      ],
      contactWear: [
        { from: 0, to: 25, color: "#CCEAD8", name: "HI1", y: 25 },
        { from: 25, to: 50, color: "#fff6cc", name: "HI3", y: 25 },
        { from: 50, to: 75, color: "#fce8d1", name: "HI4", y: 25 },
        { from: 75, to: 100, color: "#f8d4d5", name: "HI5", y: 25 },
      ],
    };

    const series = seriesMap[this.target] || [];
    // console.log(series);
    return series;
  }

  createChart() {
    const self = this;
    this.chartOptions = {
      chart: {
        type: "gauge",
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: "transparent",
        spacing: [0, 0, 0, 0],

        events: {
          load() {
            const chart = this as Highcharts.Chart;

            const newX = chart.plotWidth / 2 + chart.plotLeft,
        newY = self.status.length > 13
            ? chart.plotHeight / 2.5 + chart.plotTop
            : chart.plotHeight / 1.5 + chart.plotTop;

            chart.renderer
              .text(
                self.status,
                newX,
                newY
              )
              .css({
                fontSize: "28px",
                fontWeight: "bold",
                color: "white",
              })
              .attr({
                align: "center",
              })
              .attr({
                zIndex: 8,
              })
              .add();

              
      const yAxis = chart.yAxis[0];
      const point = chart.series[0].points[0];

      // Calculate the angle based on gauge value
      const value = 30;
      const startAngle = (yAxis as any).startAngleRad; // in radians
      const endAngle = (yAxis as any).endAngleRad; // in radians
      const min = yAxis.min;
      const max = yAxis.max;

      // Map value to angle
      const angle =
        startAngle + ((value - min) / (max - min)) * (endAngle - startAngle);

      // Calculate needle end point
      const centerX = chart.plotLeft + (yAxis as any).center[0];
      const centerY = chart.plotTop + (yAxis as any).center[1] - 2;
      const radius = ((yAxis as any).center[2] / 1.8) * 0.9; // Needle length

      const endX = centerX + Math.cos(angle) * radius;
      const endY = centerY + Math.sin(angle) * radius;

      // Custom needle using renderer
      chart.renderer
        .path(["M", centerX, centerY, "L", endX, endY])
        .attr({
          stroke: "white",
          "stroke-width": 5,
          "stroke-linecap": "round",
        })
        .attr({
          zIndex: 7,
        })
        .add();

      const radius1 = ((yAxis as any).center[2] / 2) * 0.9; // Needle length

      const endX1 = centerX + Math.cos(angle) * radius1;
      const endY1 = centerY + Math.sin(angle) * radius1;

      // Custom needle using renderer
      chart.renderer
        .path(["M", centerX, centerY, "L", endX1, endY1])
        .attr({
          stroke: "#333333",
          "stroke-width": 5.5,
          "stroke-linecap": "round",
        })
        .attr({
          zIndex: 7,
        })
        .add();

          },
        },
      },
      title: { text: null },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        center: ["50%", "70%"],
        size: "100%",
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPosition: "inside",
        tickLength: 7,
        tickInterval: 25,
        tickWidth: 3,
        lineWidth: 0,
        tickPositions: this.tickPositions,
        minorTickInterval: null,
        tickColor: "#000000",
        labels: {
          enabled: true,
          distance: 10,
          step: 1,
          style: {
            fontSize: "10px",
          },
          formatter: function () {
            const index = self.tickPositions.indexOf(this.value);
            console.log(index);
            console.log(self.getLabelText(index));
            return self.getLabelText(index);
            // const tickPositions = [20, 40, 60, 80, 100];
            // const index = tickPositions.indexOf(
            //   parseInt(this.value.toString())
            // );
            // const health = ["HI1", "HI2", "HI3", "HI4", "HI5"];
            // return health[index];
          },
          // align: "center",
        },
        plotBands: this.plotBands,
      },
      plotOptions: {
        gauge: {
          dial: {
            radius: "0",
          },
          pivot: {
            radius: 0,
          },
        },
        pie: {
          opacity: 0.5,
          borderWidth: 0,
          enableMouseTracking: false,
          dataLabels: { enabled: false },
          states: {
            inactive: {
              opacity: 0.5, // Ensure background pie never dims
            },
          },
        },
      },
      series: [
        // Pie chart as background color bands
        {
          type: "pie",
          name: "Bands",
          size: "102%",
          borderWidth: 3,
          borderColor: "#000000",
          enableMouseTracking: false,
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "74%"],
          showInLegend: false,
          zIndex: 0, // Background Layer
          data: this.setPieSeries(),
        },
        // Gauge needle series
        {
          type: "gauge",
          name: "Needle",
          data: [this.selectedValue], // Adjust the value
          zIndex: 5, // Above the pie
          dataLabels: { enabled: false },
        },
      ],
      credits: { enabled: false },
      exporting: { enabled: false },
    };
  }
}
