import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { UploadComponent } from './components/upload/upload.component';
import { WorkPerformedComponent } from './components/work-performed/work-performed.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { WorkPerformedModalComponent } from './components/work-performed-modal/work-performed-modal.component';
import { HighchartPocComponent } from './components/highchart-poc/highchart-poc.component';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { CamelToSpacePipe } from './pipes/camel-to-space.pipe';
import { FeederInfoComponent } from './components/feeder-info/feeder-info.component';
import { SubAssetDetailsComponent } from './components/subasset-details/subasset-details.component';
import { HealthkpiComponent } from './components/healthkpi/healthkpi.component';
import { IndexChartComponent } from './components/index-chart/index-chart.component';
import { LinearGradientChartComponent } from './components/linear-gradient-chart/linear-gradient-chart.component';
import { SubassetMatrixComponent } from './components/subasset-matrix/subasset-matrix.component';
import { ReportTableComponent } from './components/report-table/report-table.component';
import { ReportCalenderComponent } from './components/report-calender/report-calender.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { KeysPipe } from './components/report-table/report-table.pipe';
import { PlainTableComponent } from './components/plain-table/plain-table.component';
import { SearchPipe } from './pipes/search.pipe';
import { AssetDetailsAdminComponent } from './components/asset-details-admin/asset-details-admin.component';
import { FeederInfoEditComponent } from './components/feeder-info-edit/feeder-info-edit.component';
import { ListTableWidgetComponent } from './components/list-table-widget/list-table-widget.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderComponent } from './components/loader/loader.component';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { IconComponent } from './components/icon/icon.component';
import { SearchComponent } from './components/search/search.component';
import { GroupFilterComponent } from './components/group-filter/group-filter.component'
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { AzureMapsComponent } from './components/azure-maps/azure-maps.component';
import { UtcToLocalPipe } from '../_pipes/utc-to-local.pipe';


@NgModule({
    declarations: [
        UploadComponent,
        WorkPerformedComponent,
        PaginationComponent,
        WorkPerformedModalComponent,
        HighchartPocComponent,
        AssetDetailsComponent,
        AssetDetailsAdminComponent,
        CamelToSpacePipe,
        FeederInfoComponent,
        FeederInfoEditComponent,
        SubAssetDetailsComponent,
        HealthkpiComponent,
        IndexChartComponent,
        LinearGradientChartComponent,
        SubassetMatrixComponent,
        ReportTableComponent,
        ReportCalenderComponent,
        PieChartComponent,
        StackedBarChartComponent,
        DonutChartComponent,
        KeysPipe,
        PlainTableComponent,
        SearchPipe,
        ListTableWidgetComponent,
        ConfirmationDialogComponent,
        LoaderComponent,
        HighlighterPipe,
        IconComponent,
        SearchComponent,
        GroupFilterComponent,
        AzureMapsComponent,
        UtcToLocalPipe
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
        SharedRoutingModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatRadioModule,
        MatChipsModule,
    ],
    exports: [
        UploadComponent,
        WorkPerformedComponent,
        PaginationComponent,
        HighchartPocComponent,
        AssetDetailsComponent,
        AssetDetailsAdminComponent,
        CamelToSpacePipe,
        FeederInfoComponent,
        FeederInfoEditComponent,
        SubAssetDetailsComponent,
        HealthkpiComponent,
        SubassetMatrixComponent,
        IndexChartComponent,
        LinearGradientChartComponent,
        ReportTableComponent,
        ReportCalenderComponent,
        PieChartComponent,
        StackedBarChartComponent,
        DonutChartComponent,
        PlainTableComponent,
        SearchPipe,
        ListTableWidgetComponent,
        ConfirmationDialogComponent,
        LoaderComponent,
        HighlighterPipe,
        IconComponent,
        SearchComponent,
        GroupFilterComponent,
        AzureMapsComponent,
        UtcToLocalPipe
    ]
})
export class SharedModule { }
