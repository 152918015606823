import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./_services/authentication.service";
import { Observable, Subscription } from "rxjs";
import { IdleCheckService } from "./_services/idle-check.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "./shared/components/confirmation-dialog/confirmation-dialog.component";
import { IPredictService } from "./_services/i-predict.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "web-app";
  errorMessage = "";
  innerHeight!: string;
  public lastActiveDate$: Observable<Date>;
  private timeoutId: any;
  inActiveDialog: any;
  private subscription: Subscription = new Subscription();

  constructor(
    public auth: AuthenticationService,
    private router: Router,
    private idleCheckService: IdleCheckService,
    public dialog: MatDialog,
    private iPredictService: IPredictService
  ) {
    this.idleCheckService.setUp();
    this.lastActiveDate$ = this.idleCheckService.lastActive$;
  }

  ngOnInit(): void {
    this.start();
    this.innerHeight = window.innerHeight + "px";
    this.idleStateStart();
    this.listenForContinueSession();
  }

  idleStateStart() {
    this.subscription = this.lastActiveDate$.subscribe((res) => {
      this.resetActivity();
      // console.log('last ',res);
      this.timeoutId = setTimeout(() => {
        // console.log('Inactivity detected');
        this.subscription.unsubscribe();
        this.openInactiveDialog();
      }, 25 * 60 * 1000);
    });
  }

  private listenForContinueSession() {
    window.addEventListener("storage", (event) => {
      if (event.key === "continueSession" && event.newValue) {
        console.log("Continue Session Event");
        this.inActiveDialog?.close();
      }
      if (event.key === this.auth.getAccessKey() && event.newValue) {
        console.log("Continue login Event");
      }
    });
  }

  private setContinueSessionFlag() {
    localStorage.setItem("continueSession", Date.now().toString());
  }

  resetActivity() {
    this.inActiveDialog?.close();
    clearTimeout(this.timeoutId);
  }

  openInactiveDialog() {
    localStorage.removeItem("continueSession");
    this.inActiveDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      autoFocus: true,
      width: "500px",
      data: {
        title: "Attention!",
        message:
          'Your session is about to expire in 5 Minutes. Click "Continue Session" to stay logged in',
        okButtonText: "Continue Session",
        isCancel: false,
      },
    });
    this.inActiveDialog.afterClosed().subscribe((result) => {
      this.setContinueSessionFlag();
      this.idleStateStart();
      // On Continue Session, Do nothing
    });
  }

  onLogout(): void {
    this.iPredictService.logout().subscribe((data) => {
      const URI = data.response;
      const allowlist = "ionecloud.lntebg.com";
      const allowProd = "smartcommcloud.lntebg.com";
      localStorage.clear();
      if (URI.indexOf(allowlist) > -1 || URI.indexOf(allowProd) > -1) {
        window.location.href = (data?.response).toString();
      }
    });
  }

  start() {
    console.log("start on the app");
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    if (!this.auth.checkAuthentication()) {
      if (window.location.href.includes("register")) {
        this.getRegisterPage();
      } else if (window.location.href.includes("localhost")) {
        console.log('app loaded on localhost');
        const token = ""
        this.auth.setTokenOnLocal(token);
        // on localhost do nothing
      } else if (code) {
        console.log('got storecode from url');
        this.getToken(code);
      } else {
        console.log('got nothing');
        this.login();
      }
    } else {
      console.log("user logged in");
    }
  }

  getRegisterPage() {
    if (this.auth.checkAuthentication()) {
      this.router.navigate(["/layout/dashboard"]);
    } else {
      this.router.navigate(["/register"]);
    }
  }

  getToken(code: any) {
    this.auth.fetchToken(code, "CODE").subscribe({
      next: (data) => {
        console.log('got token');
        let url = window.location.origin;
        if (window.location.pathname) {
          url += window.location.pathname;
        }
        window.location.replace(url);
        // window.location.reload();
      },
      error: (err) => {
        this.errorMessage = err?.error?.message;
      },
    });
  }

  login() {
    this.auth.login().subscribe({
      next: (data) => {
        console.log('loading to login');
        window.location.href = data.toString();
      },
      error: (err) => {
        this.errorMessage = err?.error?.message;
      },
    });
  }
}