import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../_services/shared.service';

@Pipe({
    name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {

    constructor(private sharedService: SharedService) { }

    transform(value: string): string {
        return this.sharedService.convertUTCToLocal(value);
    }
}
