import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators'
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
import { SharedService } from '../_services/shared.service';
import { PushNotificationService } from '../_services/push-notification.service';


@Injectable({
    providedIn: 'root'
})
export class RbacService {
    baseUrl = environment.apiURL;
    isDarkTheme = new BehaviorSubject<boolean>(true);

    private modules = [
        'DigitalLogbook',
        'Reports',
        'QrCodeManagement',
        'DigitalMaintenanceManager',
        'ServiceRequest',
        'IBData',
        'Settings',
        'AssetManagement',
        'UserManagement',
        'TemplateManagement',
        'SubscriptionManagement',
        'AlarmOrEvents',
        'AssetAndSubAsset',
        'ConditionMonitoring',
        'HealthKpi',
        'WhatsNew',
        'Insights',
        'GatewayManagement',
        'Help',
        'SiteOverview'
    ]

    private rolePermissions: { [key: string]: string[] } = {
        'sdmin': [
            '/layout/home',
            '/layout/dashboard',
            '/layout/sites/*',
            '/layout/alarms',
            '/layout/DMM/*',
            '/layout/DMM/overview',
            '/layout/DMM/plans',
            '/layout/DMM/workPermit',
            '/layout/DMM/workPermit/task',
            '/layout/DLB/*',
            '/layout/DLB/documents',
            '/layout/DLB/uploads',
            '/layout/DLB/design',
            '/layout/DLB/report',
            '/layout/DLB/qrcode',
            '/layout/settings/general',
            '/layout/settings/dmm',
            '/layout/settings/conditionMonitoring',
            '/layout/settings/algorithm',
            '/layout/settings/lifecycleManagement',
            '/layout/settings/remedialActions',
            '/layout/adminpanel/assetmanagement',
            '/layout/adminpanel/assetmanagement/customer',
            '/layout/adminpanel/assetmanagement/sites',
            '/layout/adminpanel/assetmanagement/assets',
            '/layout/adminpanel/assetmanagement/requests',
            '/layout/adminpanel/assetmanagement/ingestedData',
            '/layout/adminpanel/usermanagement',
            '/layout/adminpanel/subscription',
            '/layout/adminpanel/templatemanagement',
            '/layout/adminpanel/gatewaymanagement',
            '/layout/insights',
            '/layout/whatsnew',
            '/layout/help',
        ],
        'notAdmin': [
            "/layout/settings/remedialActions",
            "/layout/adminpanel/gatewaymanagement",
            "/layout/adminpanel/assetmanagement/ingestedData",
            "/layout/insights",
            "/layout/DLB/qrcode/qrcodeBulk",
            "/layout/adminpanel/subscription/addsubscription"
        ]
      };

    constructor(private http: HttpClient,
        private sharedService: SharedService,
        private pushNotificationService: PushNotificationService) { }


    getUserInformation(): Observable<User> {
        // console.log('env');
        // console.log(environment);
        const userAccounturl = `${this.baseUrl}/accesscontrol/Account`
        return this.http.get<any>(userAccounturl).pipe(
            map(response => {
                this.sharedService.addUser(response.response);
                this.sharedService.addModule(response.response.entityAccessDetails);
                this.pushNotificationService.getAvailableSubscription(response.response.id);
                const isDarkMode = response.response?.isDarkMode;
                this.isDarkTheme.next(isDarkMode);
                let bodyTag = document.documentElement.getElementsByTagName('body');
                bodyTag[0].setAttribute('class', isDarkMode ? 'dark-theme' : 'light-theme');
                isDarkMode ? this.setDarkTheme() : this.setLightTheme();
                return response.response;
            })
        )
    }

    setLightTheme() {
        let containerClasses = document.getElementById('theme-mode')?.classList;
        if (containerClasses) document.getElementById('theme-mode').setAttribute('class', containerClasses + ' light-theme');

        document.documentElement?.style.setProperty('--primary-bg', 'white');
        document.documentElement?.style.setProperty('--secondary-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--header-bg', 'white');
        document.documentElement?.style.setProperty('--header-text', '#0000008c');
        document.documentElement?.style.setProperty('--nav-bg', '#024D87');
        document.documentElement?.style.setProperty('--nav-text', 'white');
        document.documentElement?.style.setProperty('--nav-border', '#055999');
        document.documentElement?.style.setProperty('--dashboard-bg', 'white');
        document.documentElement?.style.setProperty('--dashboard-card-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--main-bg', 'white');
        document.documentElement?.style.setProperty('--main-header-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--card-bg', 'white');
        document.documentElement?.style.setProperty('--card-border', '#DDDDDD');
        document.documentElement?.style.setProperty('--card-label-text', '#A0A0A0');
        document.documentElement?.style.setProperty('--card-normal-text', 'black');

        document.documentElement?.style.setProperty('--highlight-text', '#0077BD');

        document.documentElement?.style.setProperty('--inner-tabel-text', 'black');
        document.documentElement?.style.setProperty('--inner-table-text-normal', 'black');
        document.documentElement?.style.setProperty('--inner-table-header-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--modal-body-bg', 'white');
        document.documentElement?.style.setProperty('--modal-header-bg', '#EDEDED');
        document.documentElement?.style.setProperty('--modal-label-text', '#A0A0A0');
        document.documentElement?.style.setProperty('--modal-text', 'black');

        document.documentElement?.style.setProperty('--asset-progress-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--trends-card-bg', '#FAFAFA');

    }

    setDarkTheme() {
        let containerClasses = document.getElementById('theme-mode')?.classList;
        if (containerClasses) document.getElementById('theme-mode').setAttribute('class', containerClasses + ' dark-theme');

        document.documentElement?.style.setProperty('--primary-bg', 'white');
        document.documentElement?.style.setProperty('--secondary-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--header-bg', '#005c8e');
        document.documentElement?.style.setProperty('--header-text', '#ffffff8c');

        document.documentElement?.style.setProperty('--nav-bg', 'white');
        document.documentElement?.style.setProperty('--nav-text', '#004C97');
        document.documentElement?.style.setProperty('--nav-border', '#E1E1E1');
        document.documentElement?.style.setProperty('--dashboard-bg', '#272953');
        document.documentElement?.style.setProperty('--dashboard-card-bg', '#272953');

        document.documentElement?.style.setProperty('--main-bg', '#272953');
        document.documentElement?.style.setProperty('--main-header-bg', '#272953');

        document.documentElement?.style.setProperty('--card-bg', '#383A60');
        document.documentElement?.style.setProperty('--card-border', '#383A60');
        document.documentElement?.style.setProperty('--card-label-text', '#A0A0A0');
        document.documentElement?.style.setProperty('--card-normal-text', 'white');

        document.documentElement?.style.setProperty('--highlight-text', 'white');

        document.documentElement?.style.setProperty('--inner-table-text', 'white');
        document.documentElement?.style.setProperty('--inner-table-text-normal', '#A0A0A0');
        document.documentElement?.style.setProperty('--inner-table-header-bg', '#EDEDED');

        document.documentElement?.style.setProperty('--modal-body-bg', '#383A60');
        document.documentElement?.style.setProperty('--modal-header-bg', '#EDEDED');
        document.documentElement?.style.setProperty('--modal-label-text', '#A0A0A0');
        document.documentElement?.style.setProperty('--modal-text', 'white');

        document.documentElement?.style.setProperty('--asset-progress-bg', '#272953');

        document.documentElement?.style.setProperty('--trends-card-bg', '#272953');
    }

    canAccess(module: string, access: string) {
        let modules = this.sharedService.modules;
        // console.log(modules);
        let moduleObj = modules?.filter((item: any) => item.moduleName == module);
        // console.log(moduleObj);
        let entityObj = moduleObj[0]?.entityAccessDetails.filter((item: any) => item.entityName == module)
        // console.log(entityObj);
        return entityObj[0][access];
    }

    canHaveUrlAccess(url: string, isAdmin: boolean){
        if(!isAdmin){
            const noUserAccess = this.rolePermissions['notAdmin'];
            return noUserAccess.includes(url) ? false : true;
        }
        return true
    }

}
