import { Component, AfterViewInit, EventEmitter, Input, Output, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Options } from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HC_exporting from "highcharts/modules/exporting";
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/_services/shared.service';
import { ChartParams } from "../../../_models/conditionmonitoring-cards.type";
import { ChartApiService } from './chart-api.service';
import { interval, takeUntil, Subject } from 'rxjs';
import { formatDate } from '@angular/common';

HC_more(Highcharts);
HC_exporting(Highcharts);

(function (Highcharts: any) {
    /**
     * Create a global getSVG method that takes an array of charts as an
     * argument
     */

    Highcharts.getSVG = function (charts: any) {
        var svgArr: any = [],
            top = 0,
            width = 0;
        // Highcharts.each(charts, function (chart: any) {
        //     if (chart) {

        //         var svg = chart.getSVG(),
        //             svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
        //             svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];

        //         width += svgWidth;
        //         top = Math.max(top, svgHeight);
        //         svgArr.push(svg);
        //     }
        // });
        charts.forEach(chart => {
            if (chart) {

                var svg = chart.getSVG(),
                    svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
                    svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];

                width += svgWidth;
                top = Math.max(top, svgHeight);
                svgArr.push(svg);
            }
        });

        return (
            '<svg height="' +
            400 +
            '" width="' +
            600 +
            '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
            svgArr.join("") +
            "</svg>"
        );
    };

    /**
     * Create a global exportCharts method that takes an array of charts as an
     * argument, and exporting options as the second argument
     */
    Highcharts.exportCharts = function (charts: any, group: string, options?: any) {

        charts.forEach(function (chart: any) {
            chart?.update({
                title: {
                    text: group,
                },
                legend: {
                    enabled: true
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontSize: '7px'
                            }
                        }
                    }
                }
            });
        });

        // Merge the options
        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        // Post to export server
        Highcharts.post(options.url, {
            filename: options.filename || "chart",
            type: options.type,
            scale: 2,
            svg: Highcharts.getSVG(charts)
        });

        charts.forEach(function (chart: any) {
            chart?.update({
                title: {
                    text: '',
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            });
        });
    };
})(Highcharts);

@Component({
    selector: 'app-highchart-poc',
    templateUrl: './highchart-poc.component.html',
    styleUrls: ['./highchart-poc.component.scss']
})
export class HighchartPocComponent implements AfterViewInit, OnInit {

    @Input() group!: string;
    @Input() data: any;
    @Input() isCommon!: boolean;
    @Output() close = new EventEmitter();
    name: any = {
        'curr': 'Current',
        'time': 'Time',
        'power': 'Power',
        'status': 'Status',
        'bb': 'Temperature-Busbar',
        'top': 'Temperature-Top Terminal',
        'bot': 'Temperature-Bottom Terminal',
        'env': 'Environment Data',
        'volt': 'Voltage',
        'bbfp': 'Temperature-Busbar-Fish plate'
    }
    colors: string[] = ['#FF0404', '#EFEF31', '#0873F7', '#FFC000', '#FFC0CB', '#800080', '#69BB46', '#000', '#87CEEB'];
    chart: any;
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Options = {};
    myChart: any;
    showZoomButtons: boolean = false;
    series: any;
    yAxis: any;
    selectedOption: string = '';
    reset = true;
    icons: { [key: string]: any } = {
        'curr': '../../../../assets/images/trends/current.svg',
        'time': '../../../../assets/images/trends/hour.svg',
        'power': '../../../../assets/images/trends/power.svg',
        'status': '../../../../assets/images/trends/status.svg',
        'bb': '../../../../assets/images/trends/temperature.svg',
        'top': '../../../../assets/images/trends/temperature.svg',
        'bot': '../../../../assets/images/trends/temperature.svg',
        'volt': '../../../assets/images/trends/voltage.svg',
        'env': '../../../assets/images/trends/environment.svg'
    };
    @Input() params!: ChartParams;
    chartData: any;
    play: boolean = false;

    private subscription: Subscription = new Subscription();
    offsetDate: any;
    gridLineWidth: number = 1;
    mySubscription!: Subscription;
    destroy$: Subject<boolean> = new Subject<boolean>();
    marker: boolean = true;
    scaled: boolean = false;
    clone_container = null;
    clone_tooltip = null;
    cloneToolTip = null;
    tooltip: Boolean = true;
    isLoading: boolean = false;
    selectedSeries: any;



    constructor(private chatApiService: ChartApiService, private sharedService: SharedService) {

    }

    ngOnInit(): void {
        this.selectedSeries = this.data.values?.map(item => item.tag);
        this.fetchChartData();
        this.offsetDate = (this.sharedService.user.customer) ? this.sharedService.user.customer.utcOffset : '';
    }

    ngAfterViewInit(): void {
        // this.createChartLine();
    }

    getData() {
        this.isLoading = true;
        this.series = [];
        this.yAxis = [];
        let self = this;
        if (this.data && this.data.values) {
            // this.data.values.forEach((data: any, i: number) => {
            //     console.log("____________________________");
            //     this.series.push({
            //         name: data.tag, data: [], color: this.colors[i], type: 'line', visible: true, yAxis: i, pointInterval: 24 * 3600 * 1000,
            //         tooltip: {
            //             valueSuffix: data.unit
            //         },
            //         marker: {
            //             enabled: this.marker
            //         },
            //         turboThreshold: 50000
            //     });
            //     this.yAxis.push({
            //         title: {
            //             text: undefined
            //         },
            //         lineWidth: 2,
            //         // min: this.reset ? 0 : undefined,
            //         gridLineWidth: this.gridLineWidth,
            //         labels: {
            //             format: '{value}',
            //             formatter: function () {
            //                 return this.value + data.unit;
            //             },
            //             value: undefined,
            //             style: {
            //                 color: this.colors[i]
            //             }
            //         },
            //         id: data.tag,
            //         opposite: i % 2 ? true : false,
            //         plotLines: [{
            //             color: '#808080',
            //             width: 0,
            //             value: data.limit1,
            //             zIndex: 1,
            //             className: 'hide',
            //             label: {
            //                 text: i % 2 ? "◀" : "▶",
            //                 x: i % 2 ? 3 : -5,
            //                 y: 6,
            //                 align: i % 2 ? 'right' : 'left',
            //                 style: {
            //                     "color": this.colors[i],
            //                     "fontSize": "0"
            //                 }
            //             }
            //         },
            //         {
            //             color: '#808080',
            //             width: 0,
            //             value: data.limit2,
            //             zIndex: 1,
            //             className: 'hide',
            //             label: {
            //                 text: i % 2 ? "◀" : "▶",
            //                 x: i % 2 ? 3 : -5,
            //                 y: 6,
            //                 align: i % 2 ? 'right' : 'left',
            //                 style: {
            //                     "color": this.colors[i],
            //                     "fontSize": "0"
            //                 }
            //             }
            //         }],
            //         showEmpty: false,
            //         tickWidth: 1
            //     });

            // })
            // this.chartData.forEach((point: any) => {
            //     this.series.forEach((series: any) => {
            //         if (series.name === point.tag) {
            //             let date = formatDate(new Date(point.time).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
            //             series.data.push({
            //                 x: new Date((date).split('.')[0]).getTime(),
            //                 y: parseFloat(point.value)
            //             })
            //         }
            //     });
            // });
            // this.createChartLine();


            const seriesMap = new Map();
            const yAxisMap = new Map();

            this.data.values.forEach((data: any, i: number) => {
                const color = this.colors[i];
                const isOpposite = i % 2 === 1;
                const labelAlign = isOpposite ? 'right' : 'left';
                const labelX = isOpposite ? 3 : -5;
                const labelText = isOpposite ? "◀" : "▶";

                const series = {
                    name: data.tag,
                    data: [],
                    color: color,
                    type: 'line',
                    visible: true,
                    yAxis: i,
                    pointInterval: 24 * 3600 * 1000,
                    tooltip: {
                        valueSuffix: data.unit
                    },
                    marker: {
                        enabled: this.marker
                    },
                    turboThreshold: 50000
                };

                const yAxis = {
                    title: {
                        text: undefined
                    },
                    lineWidth: 2,
                    gridLineWidth: this.gridLineWidth,
                    labels: {
                        format: '{value}',
                        formatter: function () {
                            return this.value + data.unit;
                        },
                        style: {
                            color: color
                        }
                    },
                    id: data.tag,
                    opposite: isOpposite,
                    plotLines: [
                        {
                            color: '#808080',
                            width: 0,
                            value: data.limit1,
                            zIndex: 1,
                            className: 'hide',
                            label: {
                                text: labelText,
                                x: labelX,
                                y: 6,
                                align: labelAlign,
                                style: {
                                    color: color,
                                    fontSize: "0"
                                }
                            }
                        },
                        {
                            color: '#808080',
                            width: 0,
                            value: data.limit2,
                            zIndex: 1,
                            className: 'hide',
                            label: {
                                text: labelText,
                                x: labelX,
                                y: 6,
                                align: labelAlign,
                                style: {
                                    color: color,
                                    fontSize: "0"
                                }
                            }
                        }
                    ],
                    showEmpty: false,
                    tickWidth: 1
                };

                seriesMap.set(data.tag, series);
                yAxisMap.set(data.tag, yAxis);
            });

            this.chartData.forEach((point: any) => {
                const series = seriesMap.get(point.tag);
                if (series) {
                    const date = formatDate(new Date(point.time).toUTCString(), 'MMM d, y, h:mm:ss a', 'en-US', this.offsetDate);
                    series.data.push({
                        x: new Date(date.split('.')[0]).getTime(),
                        y: parseFloat(point.value)
                    });
                }
            });

            this.series = Array.from(seriesMap.values());
            this.yAxis = Array.from(yAxisMap.values());

            this.createChartLine();
        }

    }


    private createChartLine(): void {
        let self = this;
        this.chartOptions = {
            chart: {
                type: 'line',
                panning: {
                    type: 'xy'
                },
            },
            accessibility: {
                enabled: false
            },
            exporting: {
                enabled: false,
            },
            title: {
                text: '',
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
            },
            time: {
                useUTC: false
            },
            yAxis: this.yAxis,
            xAxis: {
                type: 'datetime',
                minRange: 1,
                labels: {
                    format: '{value:%d.%m.%Y <br> %H:%M:%S}',
                    style: {
                        fontSize: '10px'
                    }
                },
                crosshair: {
                    width: 1,
                    color: '#0873F7'
                },
                startOnTick: false,
                endOnTick: false,
                minTickInterval: 60
            },
            tooltip: {
                // enabled: false,
                hideDelay: 20000,
                headerFormat: '<table class="m-2 p-2"><tr><th colspan="2"></th></tr>',
                pointFormat: `<tr style='border:unset;border-bottom:1px solid #CECECE;padding:10px' class='p-1'><td style="color: {series.color};font-size:15px">
        <i class='fa fa-square me-2'></i><b>
        {point.y}
       </b></td>
        <td class="ms-2 ps-3"><span style='font-size:13px'>{point.x: %d/%m/%Y}</span><span style='font-size:13px'>&nbsp;&nbsp;{point.x:%H:%M:%S}</span></td></tr>`,
                footerFormat: '</table>',
                shared: true,
                split: false,
                useHTML: true,
                borderWidth: 1,
                borderColor: '#BEBEBE',
                borderRadius: 5,
                backgroundColor: 'white',
                padding: 5,
                xDateFormat: '%Y-%m-%d'
            },
            series: this.series,
            plotOptions: {
                line: {
                    marker: {
                        enabled: false,
                        symbol: 'circle'
                    },
                    lineWidth: 1,
                    //ToDO: Keeping this commented as it may be needed  
                    //   point: {
                    //     events: {
                    //       click: function () {
                    //         console.log(this);
                    //         if (self.cloneToolTip) {
                    //           self.chart.container.firstChild.removeChild(self.cloneToolTip);
                    //         }
                    //         self.cloneToolTip = (this as any).series.chart.tooltip.label.element.cloneNode(true);
                    //         self.chart.container.firstChild.appendChild(self.cloneToolTip);
                    //         if (self.chart.tooltip.label.div) {
                    //           if (self.chart.cloneToolTipHTML) {
                    //             self.chart.container.removeChild(self.chart.cloneToolTipHTML);
                    //           }
                    //           self.chart.cloneToolTipHTML = self.chart.tooltip.label.div.cloneNode(true);
                    //           self.chart.container.appendChild(self.chart.cloneToolTipHTML);
                    //         }
                    //       }
                    //     }
                    // }
                }
            }
        };
        this.chart = Highcharts.chart('chart-line', this.chartOptions);
        this.chart.yAxis.forEach((item: any, i: number) => {
            let min = Math.min(item.chart.options.yAxis[i].plotLines[0].value, item.dataMin, 0);
            let max = Math.max(item.chart.options.yAxis[i].plotLines[1].value, item.dataMax);
            (this.chartOptions as any).yAxis[i].min = min;
            (this.chartOptions as any).yAxis[i].max = max;
            this.chart.yAxis[i].setExtremes(min, max);
        })
    }

    changeSeriesSelection(tag: string, e: any) {
        let series = this.chartOptions.series;
        let selectedSeries = series?.filter((item: any) => item.name == tag);
        if (selectedSeries && selectedSeries[0]) {
            if (e.target.checked) {
                selectedSeries[0].visible = true
                this.chart = Highcharts.chart('chart-line', this.chartOptions);
                this.selectedSeries.push(tag);
            }
            else {
                selectedSeries[0].visible = false;
                this.chart = Highcharts.chart('chart-line', this.chartOptions);
                const index = this.selectedSeries.findIndex(item => item === tag);
                this.selectedSeries.splice(index, 1);
            }
        }
        this.selectedOption = 'zoom';
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        this.chart.options.chart.panning.enabled = false;
        this.chart.options.chart.zoomType = 'xy';
    }

    showHideLimits(tag: string, e: any, i: number) {
        let yAxes = this.chartOptions.yAxis as any;
        let selectedAxis = yAxes?.filter((item: any) => item.id == tag);
        if (selectedAxis && selectedAxis[0]) {
            if (e.target.checked) {
                selectedAxis[0].plotLines.forEach((item: any) => {
                    item.width = 1;
                    item.label.style.fontSize = '22px';
                })

                if (!this.chart.options) {
                    this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
                }
                var check = this.chart;
                var min = check.yAxis[i].min;
                var max = check.yAxis[i].max;
                var pLineMin = check.yAxis[i].chart.options.yAxis[i].plotLines[0].value;
                var pLineMax = check.yAxis[i].chart.options.yAxis[i].plotLines[1].value;
                // if (pLineMax > max) {
                //   check.yAxis[i].setExtremes(min, pLineMax);
                //   max = pLineMax;
                //   (this.chartOptions as any).yAxis[i].max = max;
                //   Highcharts.chart('chart-line', this.chartOptions as any);
                // }
                // if (pLineMin < min) {
                //   check.yAxis[i].setExtremes(pLineMin, max);
                //   min = pLineMin;
                //   (this.chartOptions as any).yAxis[i].min = min;
                //   Highcharts.chart('chart-line', this.chartOptions as any);
                // }
                this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
            }
            else {
                selectedAxis[0].plotLines.forEach((item: any) => {
                    item.width = 0;
                    item.label.style.fontSize = 0;
                })
                if (!this.chart.options) {
                    this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
                }
                // let min = this.chart.yAxis[i].dataMin < 0 ? this.chart.yAxis[i].dataMin : 0;
                // let max = this.chart.yAxis[i].dataMax;
                // (this.chartOptions as any).yAxis[i].min = min;
                // (this.chartOptions as any).yAxis[i].max = max;
                this.chart = Highcharts.chart('chart-line', this.chartOptions as any);

                //  this.chart.yAxis[i].setExtremes(min, max);
            }
        }
        this.selectedOption = 'zoom';
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        this.chart.options.chart.panning.enabled = false;
        this.chart.options.chart.zoomType = 'xy';
    }

    print() {
        (Highcharts as any).exportCharts(Highcharts.charts, this.name[this.data.group.split(' ')[0].toLowerCase()], {
            type: "application/pdf"
        });
    }

    download() {
        (Highcharts as any).exportCharts(Highcharts.charts, this.name[this.data.group.split(' ')[0].toLowerCase()]);
    }

    resetZoom() {
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        this.chart.zoomOut();
        this.reset = true;
        this.scaled = !this.scaled;
        if (this.scaled) {
            this.selectedOption = 'reset';
            this.chart.yAxis.forEach((item: any, i: number) => {
                let min = item.dataMin;
                let max = item.dataMax;
                (this.chartOptions as any).yAxis[i].min = min;
                (this.chartOptions as any).yAxis[i].max = max;
                this.chart.yAxis[i].setExtremes(min, max);
            })
        } else {
            this.selectedOption = '';
            this.chart.yAxis.forEach((item: any, i: number) => {
                let min = Math.min(item.chart.options.yAxis[i].plotLines[0].value, item.dataMin, 0);
                let max = Math.max(item.chart.options.yAxis[i].plotLines[1].value, item.dataMax);
                (this.chartOptions as any).yAxis[i].min = min;
                (this.chartOptions as any).yAxis[i].max = max;
                this.chart.yAxis[i].setExtremes(min, max);
            })
        }
    }

    zoomout() {
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        var oMax = this.chart.xAxis[0].max
        var xMin = this.chart.xAxis[0].min
        this.chart.xAxis[0].setExtremes(xMin - 2628002880, oMax);
        (this.chartOptions as any).xAxis.min = xMin - 2628002880;
    }

    zoomin() {
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        var oMax = this.chart.xAxis[0].max
        var xMin = this.chart.xAxis[0].min
        this.chart.xAxis[0].setExtremes(xMin + 2628002880, oMax);
        (this.chartOptions as any).xAxis.min = xMin + 2628002880;
    }

    enablePan() {
        if (this.selectedOption == 'pan') {
            this.selectedOption = '';
            this.chart.options.chart.panning.enabled = false;
        } else {
            this.selectedOption = 'pan';
            if (!this.chart.options) {
                this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
            }
            this.chart.options.chart.panning.enabled = true;
            this.chart.options.chart.panning.type = 'xy';
            this.chart.options.chart.zooming.type = undefined;
        }
    }

    disablePan() {
        if (this.selectedOption == 'zoom') {
            this.selectedOption = '';
            this.chart.options.chart.zooming.type = undefined;
        } else {
            this.selectedOption = 'zoom';
            if (!this.chart.options) {
                this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
            }
            this.chart.options.chart.panning.enabled = false;
            this.chart.options.chart.zooming.type = 'xy';
        }
    }

    closeModal() {
        this.close.emit({});
    }

    changeViewRange() {

    }

    fetchChartData() {
        this.isLoading = true;
        if (!this.params.duration) {
            this.params.duration = 1;
        }
        this.subscription.add(
            this.chatApiService.getChartData(this.params).subscribe((chartData: any) => {
                this.chartData = chartData;
                this.getData();
                this.isLoading = false;
            })
        )


    }

    updateChartData(e: any) {
        this.params.duration = e.target.value;
        if (!this.play)
            this.fetchChartData();
    }

    toggleChartGrid(e: any) {
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        this.gridLineWidth = this.gridLineWidth ? 0 : 1;
        (this.chartOptions as any).yAxis.forEach((item: any) => {
            item.gridLineWidth = this.gridLineWidth;
        })
        this.chart.yAxis.forEach((item: any) => {
            item.update({
                gridLineWidth: this.gridLineWidth
            })
        })
        // this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
    }

    toggleMarkers(e: any) {
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        this.marker = !this.marker;
        (this.chartOptions as any).series.forEach((item: any) => {
            item.marker = {};
            item.marker.enabled = this.marker;
        })
        // this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        this.chart.series.forEach((item: any) => {
            item.options.marker.radius = this.marker ? 4 : 0;
            item.update({
                marker: {
                    enabled: this.marker
                }
            })
        })
    }

    toggleTooltip(e: any) {
        if (!this.chart.options) {
            this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        }
        this.tooltip = !this.tooltip;
        (this.chartOptions as any).tooltip.enabled = this.tooltip;
        // this.chart = Highcharts.chart('chart-line', this.chartOptions as any);
        this.chart.update({
            tooltip: {
                enabled: this.tooltip
            }
        })
    }

    refreshChartData(e?: any) {
        this.mySubscription?.unsubscribe();
        this.selectedOption = 'play';
        let time;
        if (!e) {
            time = 60000;
        } else time = e.target.value;
        // this.fetchChartData();
        this.mySubscription = interval(time).pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.selectedSeries = this.data.values?.map(item => item.tag);
            this.fetchChartData();
        });
    }

    stopRefresh() {
        this.selectedOption = '';
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.mySubscription.unsubscribe();
        this.fetchChartData();
    }


    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        if (this.mySubscription)
            this.mySubscription.unsubscribe();
        this.subscription.unsubscribe();
        if (this.chart && this.chart.options)
            this.chart.destroy();
    }
}
