import { Component, inject } from "@angular/core";
import { AuthenticationService } from "./core/helpers/authentication.service";
import { Router } from "@angular/router";
import { BreadcrumbService } from "./shared/services/breadcrumb.service";

export enum Operation {
  None,
  StoreCode,
  ShowLoginPage,
  ExchangeCode,
  Local,
  Register,
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  title = "SmartComm";

  breadcrumbService: BreadcrumbService = inject(BreadcrumbService);
  authService: AuthenticationService = inject(AuthenticationService);
  router: Router = inject(Router);

  ngOnInit(): void {
    this.loadApp();
  }

  loadApp() {
    console.log("start on the app");
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    if (!this.authService.checkAuthentication()) {
      if (window.location.href.includes("register")) {
        this.getRegisterPage();
      } else if (window.location.href.includes("localhost")) {
        console.log("app loaded on localhost");
        localStorage.clear();
        const token =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyU2Vzc2lvbkNvbnRleHQiOiJ7XCJVc2VySWRlbnRpdHlDb250ZXh0XCI6e1wiVXNlcklkXCI6Mjk4LFwiRmlyc3ROYW1lXCI6XCJTdXBlckFkbWlublwiLFwiYWN0aXZlXCI6dHJ1ZSxcIkxhc3ROYW1lXCI6XCJJRFBcIixcIk1vYmlsZU51bWJlclwiOlwiMTIzNDU2Nzg5MFwiLFwiRW1haWxBZGRyZXNzXCI6XCJzdXBlcmFkbWluaWRwQHlvcG1haWwuY29tXCIsXCJEaXNwbGF5TmFtZVwiOlwiU3VwZXJBZG1pbm5JRFBcIixcIkhhc0FncmVlbWVudFNpZ25lZFwiOnRydWUsXCJJc0FjdGl2ZVwiOmZhbHNlLFwiQWN0aXZhdGlvbkxpbmtcIjpcIlwiLFwiUm9sZUlkXCI6NSxcIkZlZGVyYXRlZElkXCI6XCJnYjAwOWJjYy1hOTBlLWM4NmUtOTI0NC03YjViNTc0OTEzNjZcIixcIkNvbXBhbnlJZFwiOm51bGwsXCJDb21wYW55TmFtZVwiOm51bGwsXCJSb2xlTmFtZVwiOlwiRUJHLlN1cGVyQWRtaW5cIixcIlRpbWVab25lSWRcIjpcIkluZGlhIFN0YW5kYXJkIFRpbWVcIixcIklzQ3VzdEFkbWluXCI6ZmFsc2UsXCJJc1N1cGVyQWRtaW5cIjp0cnVlfSxcIkxpY2Vuc2VDb250ZXh0XCI6bnVsbH0iLCJuYmYiOjE3Mzk1NTA0MDcsImV4cCI6MTczOTU2MTIwNywiaXNzIjoiaU9uZUNsb3VkIiwiYXVkIjoibVROODhNT2tqYjhVYlNvZEMyS05WcHdrNjdWNkpLTFoifQ.f1WRMhRoZTmtLVX-wZ2vIAA5-ZHzf_rLfNdbSDCv45k";
        this.authService.setTokenOnLocal(token);
        // on localhost do nothing
      } else if (code) {
        console.log("got storecode from url");
        this.authService.getToken(code);
      } else {
        console.log("got nothing");
        this.authService.login();
      }
    } else {
      console.log("user logged in");
    }
  }
  getRegisterPage() {
    if (this.authService.checkAuthentication()) {
      this.router.navigate(["/layout/dashboard"]);
    } else {
      this.router.navigate(["/register"]);
    }
  }
}
